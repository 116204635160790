import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["daysField", "dateField"]

  connect() {
    this.toggle()
  }

  toggle() {
    const selected = this.expirationWay()

    if (selected === "days_to_expire_after_signature") {
      this.show(this.daysFieldTarget)
      this.hide(this.dateFieldTarget)
    } else if (selected === "expire_on_date") {
      this.hide(this.daysFieldTarget)
      this.show(this.dateFieldTarget)
    }
  }

  expirationWay() {
    return this.element.querySelector("select[name*='expiration_way']").value
  }

  show(element) {
    element.classList.remove("d-none")
  }

  hide(element) {
    element.classList.add("d-none")
  }
}
